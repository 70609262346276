import React from 'react'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'
import { Link } from 'gatsby'

import clock from '../assets/clock.svg'
import letter from '../assets/coin-in-letter.svg'
import bird from '../assets/join-our-team-header-bird.png'
import headerShadow from '../assets/join-our-team-header-shadow.svg'
import sofa from '../assets/person-on-sofa.svg'
import shrine from '../assets/shrine.svg'
import { Button, MainLayout, RolesSection } from '../components'

import '../styles/join-our-team.scss'

export default function JoinOurTeamPage() {
	return (
		<>
			<MainLayout>
				<header className="container relative max-w-full flex flex-col items-center justify-center">
					<div className="bird-container">
						<img
							src={bird}
							alt="bird in a rocket"
							className="w-full h-auto mb-12"
							style={{ maxWidth: '345px' }}
						/>
					</div>
					<img
						src={headerShadow}
						className="w-full object-contain max-w-2xl h-auto absolute top-0 left-1/2 transform -translate-x-1/2"
						alt="shadow"
					/>
					<h1 className="font-secondary pt-4 mb-10 text-center text-4xl leading-snug flex flex-col justify-start">
						<span className="mb-3 bg-clip-text text-transparent bg-gradient-to-b primary-gradient">
							Join our Team
						</span>
						<span className="text-lg">We are Hiring!</span>
					</h1>

					<div className="text-center text-lg">
						<p className="mb-3">
							<span className="font-secondary text-blue-900">
								Help us in our mission
							</span>
							&nbsp;to inspire children to learn, love and practice Islam with
							confidence.
						</p>
						<p>
							We’re looking for top-talent so that together we can inspire and
							educate 100,000 children Inshā Allāh!
						</p>
					</div>

					<Link
						to="/submit-application"
						className="mx-auto inline-flex my-10 max-w-80 w-full"
						tabIndex={-1}
					>
						<Button
							className="w-full px-2.5 text-xl tracking-wider text-white bg-purple-500 hover:bg-purple-300 focus:bg-purple-400 active:bg-purple-500"
							defaultColors={false}
						>
							Join the team
						</Button>
					</Link>
				</header>
				<main className="container max-w-full flex flex-col">
					<Helmet title="Deenee: Join our Team" />
					<section className="text-center">
						<h3 className="text-xl mb-4 font-secondary bg-clip-text text-transparent bg-gradient-to-b primary-gradient">
							What’s in it for you?
						</h3>
						<ul className="container p-0 text-base mb-24 flex flex-col flex-wrap items-center justify-start md:flex-row md:justify-evenly">
							<li className="flex flex-col items-center justify-start text-center w-52 m-4">
								<img
									src={letter}
									alt="coin in letter"
									className="w-12 h-12 m-6"
								/>
								Monthly compensation for your time
							</li>
							<li className="flex flex-col items-center justify-start text-center w-52 m-4">
								<img src={clock} alt="clock" className="w-12 h-12 m-6" />
								Flexible working hours – can be as little as 4 hours a week
							</li>
							<li className="flex flex-col items-center justify-start text-center w-52 m-4">
								<img
									src={sofa}
									alt="person working from home"
									className="w-12 h-12 m-6"
								/>
								Flexibility to work from any location
							</li>
							<li className="flex flex-col items-center justify-start text-center w-52 m-4">
								<img
									src={shrine}
									alt="person working from home"
									className="w-12 h-12 m-6"
								/>
								Contribute to the learning of Muslim children around the world.
							</li>
						</ul>
					</section>
					<RolesSection />
				</main>
			</MainLayout>
			<ToastContainer />
		</>
	)
}
